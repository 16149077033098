import {observer} from 'mobx-react'
import {useMemo} from 'react'
import {WildberriesOAuthForm} from '../WildberriesOAuthForm'
import {WildberriesOAuthStart} from '../WildberriesOAuthStart'

const WB_OAUTH_COMPONENTS = {
    start: WildberriesOAuthStart,
    form: WildberriesOAuthForm,
}

export const WildberriesOAuth = observer(({wbAuthStore, ...props}) => {
    const Component = useMemo(
        () => WB_OAUTH_COMPONENTS[wbAuthStore.oAuthVariant],
        [wbAuthStore.oAuthVariant]
    )
    return <Component wbAuthStore={wbAuthStore} {...props} />
})

WildberriesOAuth.displayName = 'WildberriesOAuth'
