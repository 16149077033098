import {MenuDropdown} from '@components/navigation/header/menuDropdown'
import {EyeIcon, MenuAlt2Icon} from '@heroicons/react/outline'
import {HeaderTabs} from './HeaderTabs'
import {NotificationCenter} from './NotificationCenter'
import NotificationCenterMobile from './NotificationCenter/NotificationCenterMobile'

//hooks
import {useSelector} from '@hooks/mobxStoreHooks/useSelector'
import {useResponsive} from '@hooks/responsiveHook/useResponsive'

import {ConfirmBanner} from '@components/layout/ConfirmBanner'
import {TokenService} from '@services/TokenService'
import {blockedTariff, ONBOARD_STEP} from '@utils/constants'
import {keysToCamel} from '@utils/helpers'
import {observer} from 'mobx-react'
import {useEffect} from 'react'
import {HeaderTabsMobile} from './HeaderTabsMobile'
import {HeaderTitle} from './HeaderTitle'
import {ManagerMenu} from './ManagerMenu'
import {Onboarding} from './Onboarding'
import {useOnboardingContext} from './Onboarding/context/OnboardingContext'
import SupportMenu from './SupportMenu'

const Header = observer(
    ({
        setSidebarOpen,
        tabs,
        onClickTab,
        title,
        menuOptions,
        isMarketplaces,
        children,
    }) => {
        const {md, lg} = useResponsive()
        const {isEmulations, setIsEmulations} = useSelector(
            (store) => store.authStore
        )
        const userStore = useSelector((store) => store.userStore)
        const {onboardingStepStore} = useOnboardingContext()

        useEffect(() => {
            setIsEmulations(TokenService.isEmulationExists())
        }, [])

        return (
            <div className={'sticky top-0 z-10'}>
                <ConfirmBanner />
                {!blockedTariff[userStore.userData?.tariff?.slug] &&
                !userStore.isEmulation &&
                onboardingStepStore.step &&
                onboardingStepStore.step !== ONBOARD_STEP.completed ? (
                    <Onboarding onboardingStepStore={onboardingStepStore} />
                ) : null}
                <div className='flex min-h-16 flex-shrink-0 bg-white shadow dark:border-b dark:border-gray-600 dark:bg-gray-800 dark:shadow-none'>
                    <button
                        type='button'
                        className='border-r border-gray-200 px-4 text-gray-500 focus:outline-none dark:border-none dark:text-gray-300 md:hidden'
                        onClick={() => setSidebarOpen(true)}
                    >
                        <span className='sr-only'>Open sidebar</span>
                        <MenuAlt2Icon className='h-6 w-6' aria-hidden='true' />
                    </button>
                    <div className='flex flex-1 justify-between px-4'>
                        <div className='flex flex-1'>
                            {tabs && tabs.length ? (
                                <>
                                    {lg ? (
                                        <HeaderTabs
                                            tabs={tabs}
                                            onClickTab={onClickTab}
                                        />
                                    ) : (
                                        <HeaderTabsMobile
                                            tabs={tabs}
                                            onClickTab={onClickTab}
                                        />
                                    )}
                                </>
                            ) : (
                                ''
                            )}
                            {children}
                            {!!title?.length && <HeaderTitle title={title} />}
                        </div>
                        <div className='ml-4 flex items-center space-x-5 md:ml-6'>
                            {userStore.userData.ext_manager && (
                                <ManagerMenu
                                    user={keysToCamel(userStore.userData)}
                                    manager={keysToCamel(
                                        userStore.userData.ext_manager
                                    )}
                                />
                            )}
                            <div className={'flex items-center space-x-1'}>
                                <SupportMenu />
                                {md && <NotificationCenter />}
                                {!md && <NotificationCenterMobile />}
                            </div>
                            {/* Profile dropdown */}
                            {(TokenService.isEmulationExists() ||
                                isEmulations) &&
                                !md && (
                                    <div
                                        className={
                                            'flex items-center space-x-1'
                                        }
                                    >
                                        <EyeIcon
                                            className={'h-6 w-6 text-red-500'}
                                        />
                                    </div>
                                )}
                            <MenuDropdown
                                isEmulations={
                                    TokenService.isEmulationExists() ||
                                    isEmulations
                                }
                                options={menuOptions}
                                label={userStore.userData.email || undefined}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
)

Header.displayName = 'Header'

export {Header}
