import {AppPath} from '@routesEnums'
import {TokenService} from '@services/TokenService'
import {USER_ROLE} from '@utils/constants'
import {makeAutoObservable} from 'mobx'

class UserStore {
    constructor(rootStore) {
        this.rootStore = rootStore
        makeAutoObservable(this)
    }

    userData = {}
    parentWithEmulationUserData = {}
    unverifiedUserData = {}
    userEmail = ''
    setUserData = (userData = {}) => {
        this.userData = userData
        console.log(userData)
    }
    setUnverifiedUserData = (unverifiedUserData = {}) => {
        this.unverifiedUserData = unverifiedUserData
    }
    saveUserData = () => {
        this.parentWithEmulationUserData = this.userData
    }
    resetParentWithEmulationUserData = () => {
        this.parentWithEmulationUserData = {}
    }
    setUserEmail = (email = '') => {
        this.userEmail = email
    }

    reset = () => {
        this.setUserData()
        this.setUserEmail()
    }

    get tariff() {
        return this.userData?.tariff || {}
    }

    get isBlocked() {
        return this.tariff?.slug === 'blocked'
    }

    get isPredTrial() {
        return this.tariff?.slug === 'pretrial'
    }

    get isAllTrial() {
        return this.tariff?.paid_before
    }

    get isAdminRole() {
        return (
            this.userData?.role === USER_ROLE.admin ||
            this.userData?.role === USER_ROLE.manager
        )
    }

    get isAdmin() {
        return this.userData?.role === 'admin'
    }

    get isDelegate() {
        return this.userData?.role === 'delegate'
    }

    get isEmulation() {
        return (
            !!this.userData?.parent_user_uuid ||
            this.userData?.current_login_method === 'admin'
        )
    }

    get isEmulationParentAdmin() {
        return (
            this.isEmulation &&
            this.parentWithEmulationUserData?.role === 'admin'
        )
    }

    get isReferralCode() {
        return !!this.userData?.referral_code
    }

    get isPayed() {
        return !this.tariff?.is_payable
    }

    get isFirstReferral() {
        return this.isReferralCode && this.isPayed
    }

    get userUuid() {
        return this.userData?.user_uuid || ''
    }

    get userHeadId() {
        return this.userData?.head_id || null
    }

    get userSideId() {
        return this.userData?.side_id || ''
    }

    get restrictions() {
        return this.tariff.restrictions || {}
    }

    get urlDisable() {
        return this.restrictions.url_disable || []
    }

    get limitCabinets() {
        const limit = this.restrictions?.max_cabinets || 0
        const demo = this.pim?.qty_demo || 0
        return limit + demo
    }

    get pim() {
        return this.userData?.pim || {}
    }

    get homePage() {
        if (this.isBlocked) return AppPath.profitMargin
        return this.rootStore.marketStore.isRealAcc
            ? AppPath.dashboard
            : AppPath.marketplaces
    }

    mergeUserData = (userData) => {
        this.setUserData(userData)
        this.setUserEmail(userData.email)
    }

    requestUserData = async (callback, onError) => {
        if (!TokenService.isTokenExists()) {
            if (typeof callback === 'function') callback()
            return
        }
        this.rootStore.marketStore.setLoaderAllCreds(true)
        try {
            await this.rootStore.marketStore.getList()
        } catch (e) {
            console.log(e)
        } finally {
            this.rootStore.marketStore.setLoaderAllCreds(false)
        }
    }
}

export default UserStore
