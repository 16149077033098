export const DYNAMIC_PATHS = {
    account: 'account',
    quiz: 'quiz',
    billing: 'billing',
    main: 'main',
    productAnalytics: 'productAnalytics',
    deliveriesRegions: 'deliveriesRegions',
    profit: 'profit',
    telegram: 'telegram',
    auth: 'auth',
    helpList: 'helpList',
    adminUsers: 'adminUsers',
    adminPartners: 'adminPartners',
    academy: 'academy',
    finance: 'finance',
    delegate: 'delegate',
    notifications: 'notifications',
    supply: 'supply',
    adv: 'adv',
    accesses: 'accesses',
}
