import {action, computed, makeObservable, observable, reaction} from 'mobx'

import {MenuTelegramIcon, ThinRubIcon} from '@components/elements/Icon/Icon'
import {
    AcademicCapIcon,
    ArchiveIcon,
    CalculatorIcon,
    ChartBarIcon,
    ChatAltIcon,
    CubeIcon,
    CurrencyDollarIcon,
    EyeIcon,
    FingerPrintIcon,
    LightBulbIcon,
    ShieldCheckIcon,
    ShoppingCartIcon,
    SpeakerphoneIcon,
    TrendingUpIcon,
    TruckIcon,
    UserGroupIcon,
    VideoCameraIcon,
} from '@heroicons/react/outline'
import {AdjustmentsIcon, LogoutIcon} from '@heroicons/react/solid'
import {AppPath} from '@routesEnums'
import {ModalControl} from '@store/ModalControl'
import {
    BLOCKED_NAV_ITEM,
    BLOCKED_NAV_ITEM_TOOLTIP,
    BLOCKED_PROFILE_ITEM,
    NAVIGATE_ITEM,
    USER_ROLE,
} from '@utils/constants'
import GoogleTagManager from '@utils/googleUtils/googleTagManager'
import {openChat} from '@utils/helpers'

class LayoutSidebarStore {
    constructor(rootStore) {
        this.rootStore = rootStore
        makeObservable(this, {
            navigation: observable,
            sidebarOpen: observable,
            profileOptions: observable,
            isFullSidebar: observable,
            isOpenOnboarding: observable,

            setSidebarOpen: action,
            toggleSidebar: action,
            setIsFullSidebar: action,
            setProfileOptions: action,
            addNavigation: action,
            deleteNavigation: action,
            setIsOpenOnboarding: action,
            setVisibleNavigationItem: action,
            changeBlockedItem: action,
            changeBlockedProfileItem: action,

            myProfileOptions: computed,
        })
        this.disposerisEmulations = reaction(
            () => rootStore.authStore.isEmulations,
            (isEmulations, prevIsEmulations) => {
                if (isEmulations) {
                    const obj = {
                        id: 'emulations',
                        label: 'Выйти из эмуляции',
                        icon: EyeIcon,
                        iconClassName: 'text-red-500',
                        logout: true,
                        onLogout: async () => {
                            this.rootStore.authStore.history.push(
                                AppPath.adminUsers
                            )
                            await this.rootStore.authStore.externalLogout()
                        },
                        href: undefined,
                    }
                    this.setProfileOptions(obj)
                } else if (prevIsEmulations && !isEmulations) {
                    this.profileOptions = this.profileOptions.filter(
                        (option) => option.id !== 'emulations'
                    )
                }
            }
        )

        this.disposerUserData = reaction(
            () => rootStore.userStore.userData,
            (value) => {
                this.setVisibleNavigationItem(
                    'audit',
                    'audit',
                    !value?.tariff?.paid_before
                )
            }
        )

        this.disposerDelegateAdmin = reaction(
            () => rootStore.userStore.isDelegate,
            (value) => {
                this.setVisibleNavigationItem(
                    'audit',
                    'audit',
                    !value?.tariff?.paid_before
                )
            }
        )
    }
    isOpenOnboarding = false
    telegramChannelControl = new ModalControl()

    navigation = {
        main: [
            {
                name: 'Рабочий стол',
                id: 'main',
                icon: ChartBarIcon,
                visible: true,
                active: false,
                href: AppPath.main,
                blocked: false,
                tooltip: '',
                options: [
                    {
                        id: 'dashboard',
                        name: 'Рабочий стол',
                        visible: true,
                        href: AppPath.dashboard,
                    },
                    {
                        id: 'recommendations',
                        name: 'Рекомендации',
                        visible: false,
                        href: AppPath.recommendations,
                    },
                ],
            },
            {
                name: 'Прибыль',
                id: 'margin',
                visible: true,
                active: false,
                href: AppPath.profit,
                icon: CalculatorIcon,
                blocked: false,
                tooltip: '',
                options: [
                    {
                        id: 'margin_profit',
                        name: 'Маржин. прибыль',
                        visible: true,
                        href: AppPath.profitMargin,
                    },
                    {
                        id: 'unit_economy',
                        name: 'Юнит-экономика',
                        visible: true,
                        href: AppPath.profitUnit,
                    },
                    {
                        id: 'unit_economy_promotion',
                        name: 'Акции',
                        visible: true,
                        href: AppPath.profitUnitPromotion,
                    },
                    {
                        id: 'costs',
                        name: 'Себестоимость',
                        visible: true,
                        href: AppPath.profitCosts,
                    },
                    {
                        id: 'adv',
                        name: 'РК',
                        visible: true,
                        href: AppPath.profitAdv,
                    },
                ],
            },
            {
                name: 'Финансы',
                id: 'finance',
                visible: true,
                active: false,
                href: AppPath.finance,
                icon: CurrencyDollarIcon,
                blocked: false,
                tooltip: '',
                options: [
                    {
                        id: 'standard',
                        name: 'Сводные финансы',
                        visible: true,
                        href: AppPath.financeStandard,
                    },
                    {
                        id: 'comparison',
                        name: 'Сравнение периодов',
                        visible: true,
                        href: AppPath.financeComparison,
                    },
                    {
                        id: 'dynamics',
                        name: 'Динамика',
                        visible: true,
                        href: AppPath.financeDynamics,
                    },
                ],
            },
            {
                name: 'Рекламный кабинет',
                id: 'adv',
                visible: true,
                active: false,
                href: AppPath.adv,
                icon: TrendingUpIcon,
                blocked: false,
                tooltip: '',
            },
            {
                name: 'Товарная аналитика',
                id: 'productAnalytics',
                icon: ShoppingCartIcon,
                visible: true,
                active: false,
                href: AppPath.productAnalytics,
                blocked: false,
                tooltip: '',
                options: [
                    {
                        name: 'ABC-анализ',
                        visible: true,
                        href: AppPath.productAnalyticsAbc,
                    },
                    {
                        name: 'Товарный отчет',
                        visible: true,
                        href: AppPath.productAnalyticsCommodityReport,
                    },
                    {
                        name: 'Когортный отчет',
                        visible: true,
                        href: AppPath.productAnalyticsCohort,
                    },
                    {
                        name: 'Активность товаров',
                        visible: true,
                        href: AppPath.productAnalyticsActiveProducts,
                    },
                ],
            },
            {
                name: 'Поставки и регионы',
                id: 'deliveriesRegions',
                visible: true,
                active: false,
                href: AppPath.deliveriesRegions,
                icon: TruckIcon,
                blocked: false,
                tooltip: '',
                options: [
                    {
                        name: 'Прогноз поставок',
                        visible: true,
                        href: AppPath.deliveriesRegionsSupplyForecast,
                    },
                    {
                        name: 'Прогноз поставок рег.',
                        visible: true,
                        href: AppPath.deliveriesRegionsSupplyForecastRegions,
                    },
                    {
                        name: 'Региональные продажи',
                        visible: true,
                        href: AppPath.deliveriesRegionsSalesRegion,
                    },
                    {
                        name: 'Склады',
                        visible: true,
                        href: AppPath.deliveriesRegionsRegionalStocks,
                    },
                ],
            },
            {
                name: 'Формирование поставок',
                id: 'supply',
                visible: false,
                active: false,
                href: AppPath.supply,
                icon: ArchiveIcon,
                blocked: false,
                tooltip: '',
                options: [
                    {
                        name: 'Сформировать поставку',
                        visible: true,
                        href: AppPath.supplyForecast,
                    },
                    {
                        name: 'Остатки',
                        visible: true,
                        href: AppPath.supplyRemaining,
                    },
                    {
                        name: 'Кратность короба',
                        visible: true,
                        href: AppPath.supplyBoxRatio,
                    },
                ],
            },
            {
                name: 'Телеграм канал',
                id: 'telegramChannel',
                visible: true,
                active: false,
                onClick: this.telegramChannelControl.open,
                icon: MenuTelegramIcon,
                blocked: false,
                tooltip: '',
            },
        ],
        other: [
            {
                name: 'Подключения',
                id: 'marketplaces',
                icon: CubeIcon,
                visible: true,
                active: false,
                href: AppPath.marketplaces,
                blocked: false,
                tooltip: '',
            },
        ],
        audit: [
            {
                name: 'Аудит вашего магазина',
                visible: true,
                active: false,
                id: 'audit',
                blocked: false,
                tooltip: '',
                onClick: () => {
                    this.setIsOpenOnboarding(true)
                    GoogleTagManager.dataLayer({
                        event: 'mainEvent',
                        eventCategory: 'FreeAudit',
                        eventAction: 'Click',
                        eventLabel: 'Form',
                        email: this.rootStore.userStore.userData.email,
                        user_id: this.rootStore.userStore.userData.user_id,
                    })
                },
                icon: LightBulbIcon,
            },
            {
                name: 'Чат с поддержкой',
                visible: true,
                active: false,
                button: true,
                id: 'support-chat-custom',
                blocked: false,
                tooltip: '',
                onClick: () => {
                    openChat()
                },
                icon: ChatAltIcon,
            },
            {
                name: 'Тарифы и настройки',
                id: 'accountTariff',
                visible: true,
                active: false,
                href: AppPath.accountTariff,
                icon: AdjustmentsIcon,
                blocked: false,
                tooltip: '',
            },
        ],
        services: [
            {
                name: 'Другие услуги',
                id: 'promotion',
                visible: true,
                active: false,
                href: AppPath.promotion,
                icon: SpeakerphoneIcon,
                blocked: false,
                tooltip: '',
            },
            {
                name: 'Видео',
                id: 'helpList',
                visible: true,
                active: false,
                href: AppPath.helpList,
                icon: VideoCameraIcon,
                blocked: false,
                tooltip: '',
            },
            {
                name: 'Академия Seller24',
                id: 'academy',
                visible: false,
                active: false,
                href: AppPath.academy,
                icon: AcademicCapIcon,
                blocked: false,
                tooltip: '',
            },
        ],
    }

    adminPage = {
        admin: [
            {
                name: 'Пользователи',
                id: 'adminUsers',
                visible: true,
                active: false,
                href: AppPath.adminUsers,
                icon: UserGroupIcon,
            },
            {
                name: 'Список партнеров',
                id: 'adminPartners',
                visible: true,
                active: false,
                href: AppPath.adminPartners,
                icon: ShieldCheckIcon,
            },
        ],
    }

    delegatePage = {
        delegate: [
            {
                name: 'Пользователи',
                id: 'delegateUsers',
                visible: true,
                active: false,
                href: AppPath.delegateUsers,
                icon: UserGroupIcon,
            },
        ],
    }

    accessesPage = {
        accesses: [
            {
                name: 'Доступы',
                id: 'accesses',
                visible: true,
                active: false,
                href: AppPath.accesses,
                icon: FingerPrintIcon,
            },
        ],
    }

    profileOptions = [
        {
            id: 'tariff',
            label: 'Тариф',
            icon: ThinRubIcon,
            iconClassName: 'stroke-current',
            href: AppPath.accountTariff,
        },
        {
            id: 'settings',
            label: 'Настройки',
            icon: AdjustmentsIcon,
            href: AppPath.accountSettings,
        },
        {
            id: 'logout',
            label: 'Выход',
            icon: LogoutIcon,
            iconClassName: 'text-red-500',
            logout: true,
            onLogout: () => {
                this.rootStore.authStore.logout()
            },
            href: AppPath.signIn,
        },
    ]

    sidebarOpen = false
    isFullSidebar = false

    get myProfileOptions() {
        return this.profileOptions
    }

    initialSidebar = () => {
        if (
            !this.rootStore.userStore.isAdminRole &&
            !this.rootStore.marketStore.isRealAcc &&
            this.rootStore.userStore.isPredTrial
        ) {
            this.changeBlockedItem(true, BLOCKED_NAV_ITEM_TOOLTIP)
            this.changeBlockedProfileItem(true, BLOCKED_NAV_ITEM_TOOLTIP)
        } else if (
            this.rootStore.userStore.isAdminRole ||
            this.rootStore.marketStore.isRealAcc ||
            !this.rootStore.userStore.isPredTrial
        ) {
            this.changeBlockedItem(false)
            this.changeBlockedProfileItem(false)
        }
    }

    setSidebarOpen = () => {
        this.sidebarOpen = !this.sidebarOpen
    }
    addNavigation = (obj) => {
        this.navigation = Object.assign({}, this.navigation, obj)
    }
    deleteNavigation = (key) => {
        delete this.navigation[key]
    }
    toggleSidebar = () => {
        this.isFullSidebar = !this.isFullSidebar
    }
    setIsFullSidebar = (value) => {
        this.isFullSidebar = value
    }

    setProfileOptions = (value) => {
        this.profileOptions.push(value)
    }

    setIsOpenOnboarding = (value) => {
        this.isOpenOnboarding = value
    }

    setVisibleNavigationItem = (key, id, value) => {
        const item = this.navigation[key].find((item) => item.id === id)
        item.visible = value
    }

    changeBlockedItem = (isBlocked, tooltip) => {
        const newNavigation = {}
        Object.entries(this.navigation).forEach(([name, navigator]) => {
            newNavigation[name] = navigator.map((navItem) => {
                if (BLOCKED_NAV_ITEM[navItem.id]) {
                    navItem.blocked = isBlocked
                    navItem.tooltip = tooltip
                }
                return navItem
            })
        })
        this.navigation = newNavigation
    }

    changeBlockedProfileItem = (isBlocked, tooltip) => {
        this.profileOptions.forEach((navItem) => {
            if (BLOCKED_PROFILE_ITEM[navItem.id]) {
                navItem.disabled = isBlocked
            }
        })
    }

    checkRole = () => {
        if (this.rootStore.userStore.isAdminRole) {
            this.addNavigation(this.adminPage)
        } else if (this.rootStore.userStore.isDelegate) {
            this.deleteNavigation(USER_ROLE.admin)
            this.addNavigation(this.delegatePage)
        } else {
            this.deleteNavigation(USER_ROLE.admin)
            this.deleteNavigation(USER_ROLE.delegate)
        }

        if (!this.rootStore.userStore.userHeadId) {
            this.addNavigation(this.accessesPage)
        } else {
            this.deleteNavigation(NAVIGATE_ITEM.accesses)
        }
    }

    destroy = () => {
        this.disposerisEmulations()
        this.disposerUserData()
        this.disposerDelegateAdmin()
    }
}

export default LayoutSidebarStore
