import {
    countryMap,
    marketplaceId,
    marketplaceName,
    phoneTemplate,
} from '@utils/constants'
import {phoneRegexes} from '@utils/regexes'
import Bowser from 'bowser'

export const toCamel = (s) =>
    s.replace(/([-_][a-z])/gi, ($1) =>
        $1.toUpperCase().replace('-', '').replace('_', '')
    )

export const keysToCamel = (o) => {
    const isObject = (o) =>
        o === Object(o) && !isArray(o) && typeof o !== 'function'
    const isArray = function (a) {
        return Array.isArray(a)
    }
    if (isObject(o)) {
        const n = {}

        Object.keys(o).forEach((k) => {
            n[toCamel(k)] = keysToCamel(o[k])
        })

        return n
    } else if (isArray(o)) {
        return o.map((i) => keysToCamel(i))
    }

    return o
}

export const keysToSnake = (o) => {
    const isObject = (o) =>
        o === Object(o) && !isArray(o) && typeof o !== 'function'
    const isArray = function (a) {
        return Array.isArray(a)
    }
    if (isObject(o)) {
        const n = {}

        Object.keys(o).forEach((k) => {
            n[toSnake(k)] = keysToSnake(o[k])
        })

        return n
    } else if (isArray(o)) {
        return o.map((i) => keysToSnake(i))
    }

    return o
}
export const toSnake = (string) =>
    string
        .replace(/[\w]([A-Z])/g, function (m) {
            return m[0] + '_' + m[1]
        })
        .toLowerCase()

export const clickToUrl = (link, blank = false) => {
    const a = document.createElement('a')
    a.href = link
    if (blank) a.target = '_blank'
    a.click()
}

export const getRandomNumber = (min = 100000, max = 999999) =>
    Math.floor(Math.random() * (max - min)) + min

export const timeout = (ms, value) => {
    return new Promise((resolve) =>
        setTimeout(() => {
            resolve(value)
        }, ms)
    )
}

export const lightenColor = (color, percent) => {
    const num = parseInt(color.replace('#', ''), 16),
        amt = Math.round(2.55 * percent),
        R = (num >> 16) + amt,
        B = ((num >> 8) & 0x00ff) + amt,
        G = (num & 0x0000ff) + amt
    return (
        '#' +
        (
            0x1000000 +
            (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
            (B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 +
            (G < 255 ? (G < 1 ? 0 : G) : 255)
        )
            .toString(16)
            .slice(1)
    )
}

export const toUpperCaseFirstChar = (str) => {
    if (str) return str.charAt(0).toUpperCase() + str.slice(1)
    return null
}

export const safeGetTitle = (item, fallback = 'N/A') => {
    if (item.title || item.name) {
        return item.title || item.name
    } else {
        if (typeof fallback === 'string') {
            return fallback
        } else if (typeof fallback === 'function') return fallback()
    }
}

export const isNull = (value) => typeof value === 'object' && !value

export const sortDateKeys = (obj_1) => {
    let key = Object.keys(obj_1).sort((date1, date2) => {
        const unix1 = new Date(date1).getTime()
        const unix2 = new Date(date2).getTime()
        if (unix1 < unix2) return -1
        else if (unix1 > unix2) return 1
        else return 0
    })
    // Taking the object in 'temp' object
    // and deleting the original object.
    let temp = {}

    for (let i = 0; i < key.length; i++) {
        temp[key[i]] = obj_1[key[i]]
        delete obj_1[key[i]]
    }

    // Copying the object from 'temp' to
    // 'original object'.
    for (let i = 0; i < key.length; i++) {
        obj_1[key[i]] = temp[key[i]]
    }
    return obj_1
}

export const debounce = (func, wait) => {
    let timeout
    return function executedFunction(...args) {
        const later = () => {
            timeout = null
            func(...args)
        }
        clearTimeout(timeout)
        timeout = setTimeout(later, wait)
    }
}

export const updateArray = (array, newItem) => {
    let arr = [...array]
    const itemIdIdx = array.findIndex((item) => item === newItem)
    if (itemIdIdx === -1) {
        arr.push(newItem)
    } else {
        arr = [
            ...arr.slice(0, itemIdIdx),
            ...arr.slice(itemIdIdx + 1, arr.length),
        ]
    }
    return arr
}
export const getStandName = (isLocal) => {
    let standName = isLocal ? 'local' : 'dev'
    if (import.meta.env.MODE === 'production') {
        const parts = window.location.host.split('.')
        if (parts[0] === 'app') {
            standName = 'prod'
        } else {
            standName = 'dev'
        }
    }
    return standName
}

export const wbFilter = (option) =>
    option.market === marketplaceName.Wildberries

export const ozonFilter = (option) => option.market === marketplaceName.Ozon
export const ozonAndDemoFilter = (option) =>
    option.market === marketplaceName.Ozon ||
    option.market === marketplaceName.Demo

export function deepEqual(object1, object2) {
    function isObject(object) {
        return object != null && typeof object === 'object'
    }
    const keys1 = Object.keys(object1)
    const keys2 = Object.keys(object2)
    if (keys1.length !== keys2.length) {
        return false
    }
    for (const key of keys1) {
        const val1 = object1[key]
        const val2 = object2[key]
        const areObjects = isObject(val1) && isObject(val2)
        if (
            (areObjects && !deepEqual(val1, val2)) ||
            (!areObjects && val1 !== val2)
        ) {
            return false
        }
    }
    return true
}

export const formatPhone = (phone) => {
    const nums = String(phone)
        .replace(/[ ()-]/g, '')
        .split('')
        .slice(1)
    const country = validatePhoneNumber(phone)
    let template = phoneTemplate[country]
    nums.forEach((num) => (template = template.replace('#', num)))
    return template
}

export const getErrorFields = (formError = {}) => {
    const errors = {}
    Object.entries(formError).forEach(([key, value]) => {
        errors[key] = value.join(', ')
    })

    return errors
}

export function getMonthString(number) {
    const num = Number(number)
    const lastDigit = num % 10
    const lastTwoDigits = num % 100

    if (lastTwoDigits >= 11 && lastTwoDigits <= 19) {
        return 'месяцев'
    } else if (lastDigit === 1) {
        return 'месяц'
    } else if (lastDigit >= 2 && lastDigit <= 4) {
        return 'месяца'
    } else {
        return 'месяцев'
    }
}

export function convertToTitleCase(str) {
    str = str.replace(/_/g, ' ')
    const words = str.split(' ')

    for (let i = 0; i < words.length; i++) {
        const word = words[i]
        if (word.length > 0) {
            const firstLetter = word.charAt(0).toUpperCase()
            const restOfWord = word.slice(1).toLowerCase()
            words[i] = firstLetter + restOfWord
        }
    }

    return words.join('')
}

/**
 * Преобразует массив объектов, перемещая вложенные массивы в заданное свойство.
 *
 * @param {Array<Object>} arr - Исходный массив объектов.
 * @param {Object} [options={}] - Опции для преобразования.
 * @param {string[]} options.keys - Ключи, содержащие вложенные массивы, которые нужно переместить.
 * @param {string} [options.arrayKey='subRows'] - Название ключа, в который будут перемещены вложенные массивы.
 * @param {boolean} [options.notDeep=false] - Если `false`, выполняется рекурсивное преобразование.
 * @param {Array<{ key: string, value: any }>} [options.nullPropertyKeys] - Список ключей, которые должны быть добавлены в объект с `null`-значением, если их нет.
 * @returns {Array<Object>} - Преобразованный массив объектов.
 */
export const convertArrayToSubRows = (arr, options = {}) => {
    const onAddFieldKeys = (item, fieldKeys) => {
        const newItem = {...item}
        fieldKeys.forEach((fieldKey) => {
            if (!item.hasOwnProperty(fieldKey.key)) {
                newItem[fieldKey.key] = fieldKey.value || null
            }
        })
        return newItem
    }

    return arr.map((item) => {
        let newItem = {...item}

        const arrayKey = options.arrayKey || 'subRows'

        options.keys.forEach((key) => {
            if (options.nullPropertyKeys && options.nullPropertyKeys.length) {
                newItem = {
                    ...newItem,
                    ...onAddFieldKeys(newItem, options.nullPropertyKeys),
                }
            }
            if (newItem.hasOwnProperty(key) && Array.isArray(newItem[key])) {
                newItem[arrayKey] = newItem[key]
                delete newItem[key]

                if (Array.isArray(newItem[arrayKey]) && !options.notDeep) {
                    newItem[arrayKey] = convertArrayToSubRows(
                        newItem[arrayKey],
                        options
                    )
                }
            }
        })
        return newItem
    })
}

export const getSelectedValue = (options, value) => {
    return options.find((option) => option.id === value)
}

export const hexToRGBA = (hex, opacity) => {
    // Удаление символа #, если он есть
    hex = hex.replace('#', '')

    // Проверка длины входного значения
    if (hex.length === 3) {
        hex = hex
            .split('')
            .map((char) => char + char)
            .join('')
    }

    // Преобразование HEX в RGB
    let r = parseInt(hex.substring(0, 2), 16)
    let g = parseInt(hex.substring(2, 4), 16)
    let b = parseInt(hex.substring(4, 6), 16)

    // Возвращение строки RGBA
    return `rgba(${r}, ${g}, ${b}, ${opacity})`
}

export const getName = (first, second, last) => {
    if (!first && !second && !last) return ''
    return `${first || ''} ${second || ''} ${last || ''}`.trim()
}

export const getCookie = (name) => {
    const cookies = document.cookie.split(';')
    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim()
        // Проверяем, начинается ли текущая cookie с заданного имени
        if (cookie.startsWith(name + '=')) {
            // Если да, возвращаем значение cookie
            return cookie.substring(name.length + 1)
        }
    }
    // Если cookie не найдено, возвращаем null
    return null
}

export const setCookie = (name, value, days) => {
    const expirationDate = new Date()
    expirationDate.setTime(
        expirationDate.getTime() + days * 24 * 60 * 60 * 1000
    )
    const expires = 'expires=' + expirationDate.toUTCString()
    document.cookie = name + '=' + value + ';' + expires + ';path=/'
}

export const deleteCookie = (name) => {
    document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
}

export const getRateStatus = (rate) => {
    let status = 'still'
    const percent =
        typeof rate === 'string'
            ? Number(rate.replace('%', '').replace(',', '.'))
            : rate
    if (percent > 0) {
        status = 'positive'
    } else if (percent < 0) {
        status = 'negative'
    }
    return {percent, status}
}

export const openChat = () => {
    window.carrotquest?.open()
}

export const checkIsWb = (marketId) => {
    return marketId === marketplaceId.Wildberries
}

export const checkIsOzon = (marketId) => {
    return marketId === marketplaceId.Ozon
}

export const checkNotYm = (marketId) => {
    return marketId !== marketplaceId.Yandex
}

export const parseVkVideoUrl = (url) => {
    const pattern = /video-(\d+)_(\d+)/
    const match = url.match(pattern)

    if (match) {
        const groupId = match[1]
        const videoId = match[2]
        return {groupId, videoId}
    } else {
        return {groupId: null, videoId: null}
    }
}

export const parseRutubeVideoUrl = (url) => {
    const match = url.match(/\/video\/([a-zA-Z0-9]+)/)
    if (match) {
        return match[1]
    }
    return null
}

export const getColorByValue = (value) => {
    if (value === 0) return 'gray'
    if (value > 0) return 'green'
    return 'red'
}

export const truncateString = (str, maxLength) => {
    if (str.length > maxLength) {
        return str.substring(0, maxLength) + '...'
    }
    return str
}

export const getBrowser = () => {
    const browser = Bowser.parse(window.navigator.userAgent)
    return `${browser.browser.name} ${browser.browser.version}`
}

export const getUserEnvBrowser = () => {
    const browser = Bowser.parse(window.navigator.userAgent)
    const entries = []

    if (browser.browser) {
        entries.push(`browser=${browser.browser.name}`)
        if (browser.browser.version) {
            entries.push(`version=${browser.browser.version}`)
        }
    }

    if (browser.os) {
        entries.push(`os=${browser.os.name}`)
    }

    if (browser.platform) {
        entries.push(`platform=${browser.platform.type}`)
    }

    if (browser.engine) {
        entries.push(`engine=${browser.engine.name}`)
    }

    return entries.join('|')
}

export const getCategoryOptions = (categories = []) => {
    return categories?.map((item) => ({
        key: item.categoryId,
        name: item.categoryName,
        count: item.productCount,
    }))
}

export const getBrandOptions = (brands = []) => {
    return brands.map((item) => ({
        key: item,
        name: item,
    }))
}

export const stripTrailingSlash = (str) => {
    return str.endsWith('/') ? str.slice(0, -1) : str
}

export const validatePhoneNumber = (phoneNumber = '') => {
    const cleanedNumber = phoneNumber.replace(/[\s()-]/g, '')
    if (new RegExp(phoneRegexes.validPhone).test(cleanedNumber)) {
        if (new RegExp(phoneRegexes.russia).test(cleanedNumber)) {
            return countryMap.russia
        }
        if (new RegExp(phoneRegexes.kazakhstan).test(cleanedNumber)) {
            return countryMap.kazakhstan
        }
        if (new RegExp(phoneRegexes.belarus).test(cleanedNumber)) {
            return countryMap.belarus
        }
    }

    return countryMap.russia
}

export const getCountryByPhone = (phoneNumber = '') => {
    const cleanedNumber = phoneNumber.replace(/[\s()-]/g, '')

    if (new RegExp(phoneRegexes.startPhone).test(cleanedNumber)) {
        return countryMap.russia
    }
    if (new RegExp(phoneRegexes.startPhoneBy).test(cleanedNumber)) {
        return countryMap.belarus
    }

    return countryMap.russia
}

export const mergeRefs = (...inputRefs) => {
    return (ref) => {
        inputRefs.forEach((inputRef) => {
            if (!inputRef) {
                return
            }

            if (typeof inputRef === 'function') {
                inputRef(ref)
            } else {
                inputRef.current = ref
            }
        })
    }
}

export const removeElementAtIndex = (arr, options) => {
    const index = arr.findIndex((item) => item[options.key] === options.value)
    if (index >= 0 && index < arr.length) {
        arr.splice(index, 1)
    } else {
        console.error('Индекс вне диапазона')
    }
    return arr
}

export const convertByteToMByte = (size) => {
    return (size / 1048576).toFixed(2)
}

export const copyToClipboard = async (text) => {
    try {
        await navigator.clipboard.writeText(text)
        return true
    } catch (err) {
        console.error('Ошибка копирования: ', err)
        return false
    }
}
