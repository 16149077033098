import {ToastContainerWrapper} from '@components/layout/ToastContainerWrapper'
import {OnboardingContext} from '@components/navigation/header/Onboarding/context/OnboardingContext'
import {OnboardingStepStore} from '@components/navigation/header/Onboarding/store/OnboardingStepStore'
import {NotificationsProvider} from '@components/overlays/notifications'
import {LimitBlockedProvider} from '@context/LimtiBlockedContext'
import {PathBlockedProvider} from '@context/PathBlockedContext'
import {TrialBlockedProvider} from '@context/TrialBlockedContext'
import {useHiddenConfirmCookies, useResponsive} from '@hooks'
import {useSelector} from '@hooks/mobxStoreHooks/useSelector'
import {AUTH_URLS} from '@routesEnums'
import {TokenService} from '@services/TokenService'
import {gtmAuthCompleted} from '@utils/googleUtils/utils'
import {getStandName} from '@utils/helpers'
import {callTouch} from '@utils/scripts/callTouch'
import {carrotQuestInit} from '@utils/scripts/carrotQuest'
import jwtDecode from 'jwt-decode'
import {observer} from 'mobx-react'
import {useEffect, useMemo, useRef, useState} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import {RenderRoutes, ROUTES} from './routes'
import './styles/components/alert.scss'
import './styles/components/tooltip-lite.scss'

const useConstructor = (callBack = () => {}) => {
    const hasBeenCalled = React.useRef(false)
    if (hasBeenCalled.current) return
    callBack()
    hasBeenCalled.current = true
}

const App = observer(() => {
    const history = useHistory()
    const location = useLocation()
    const authStore = useSelector((store) => store.authStore)
    const marketStore = useSelector((store) => store.marketStore)
    const userStore = useSelector((store) => store.userStore)
    const settingsStore = useSelector((store) => store.settingsStore)
    const layoutSidebarStore = useSelector((store) => store.layoutSidebarStore)
    const [onboardingStepStore] = useState(
        () => new OnboardingStepStore(marketStore, authStore)
    )

    const isOnceRef = useRef(false)
    useEffect(() => {
        if (getStandName() !== 'dev') {
            carrotQuestInit()
            callTouch()
        }
        // vkPlayerInit()

        const servicesSection = JSON.parse(
            localStorage.getItem('servicesSection')
        )
        marketStore.setServicesSection(!!servicesSection)
        return () => {
            onboardingStepStore.destroy()
        }
    }, [])
    useHiddenConfirmCookies()

    const {xxl} = useResponsive()
    useEffect(() => {
        if (xxl) {
            layoutSidebarStore.setIsFullSidebar(true)
        } else {
            layoutSidebarStore.setIsFullSidebar(false)
        }
    }, [])

    useEffect(() => {
        const token = TokenService.getToken()
        if (token) {
            const timeout = setTimeout(
                authStore.sendRefreshToken,
                jwtDecode(token).exp * 1000 - Date.now()
            )
            return () => clearTimeout(timeout)
        }
    }, [userStore.userData])

    useEffect(() => {
        layoutSidebarStore.checkRole()
        if (userStore.userData.user_uuid && !isOnceRef.current) {
            gtmAuthCompleted(userStore)
            fetchAnalyticsData()
            isOnceRef.current = true
        }
    }, [userStore.userData])

    useConstructor(() => {
        authStore.setHistory(history)
        authStore.setAuthDataFromStorages(
            !AUTH_URLS.some((url) => location.pathname.includes(url))
        )
    })

    useEffect(() => {
        if (userStore.userData.user_uuid) {
            onboardingStepStore.getOnboardCurrentStep()
        }
    }, [userStore.userData.user_uuid])

    const fetchAnalyticsData = async () => {
        await settingsStore.fetchSettingsData()
    }

    return (
        <PathBlockedProvider userStore={userStore}>
            <LimitBlockedProvider userStore={userStore}>
                <TrialBlockedProvider userStore={userStore}>
                    <NotificationsProvider>
                        <OnboardingContext.Provider
                            value={useMemo(
                                () => ({onboardingStepStore}),
                                [onboardingStepStore]
                            )}
                        >
                            <RenderRoutes routes={ROUTES} />
                            <ToastContainerWrapper />
                        </OnboardingContext.Provider>
                    </NotificationsProvider>
                </TrialBlockedProvider>
            </LimitBlockedProvider>
        </PathBlockedProvider>
    )
})

App.displayName = 'App'

export default App
