/**
 * Enum for common colors.
 * @readonly
 * @enum {string}
 */

export const AUTH_URLS = [
    '/sign_in/',
    '/sign_up/',
    '/sign_up/form/',
    '/sign_up/sms/',
    '/sign_up/qualification/',
    '/password_reset/',
    '/instructions-sent/',
    '/invite/',
    '/confirm/',
    '/quiz/',
    '/mobile/',
]

export const AppPath = Object.freeze({
    authUrl: AUTH_URLS,
    signIn: '/sign_in/',
    signUp: '/sign_up/',
    signUpForm: '/sign_up/form/',
    signUpSms: '/sign_up/sms/',
    resetPassword: '/password_reset/',
    account: '/account/',
    accountSettings: '/account/settings/',
    accountSettingsProfile: '/account/settings/profile/',
    accountSettingsPassword: '/account/settings/password/',
    accountTariff: '/account/tariff/',
    accountFileSharing: '/account/file_sharing/',
    billing: '/billing/',
    billingInvoice: '/billing/invoice/',
    billingOrderComplete: '/billing/order_complete/',
    billingOrderFail: '/billing/order_fail/',

    marketplaces: '/marketplaces/',
    marketplacesConnected: '/marketplaces/connected/',
    marketplacesImportWb: '/marketplaces/import_wb/',

    productAnalytics: '/product_analytics/',
    productAnalyticsAbc: '/product_analytics/abc/',
    productAnalyticsAbcProducts: '/product_analytics/abc/products/',
    productAnalyticsAbcCompare: '/product_analytics/abc/compare/',
    productAnalyticsCommodityReport: '/product_analytics/commodity_report/',
    productAnalyticsCohort: '/product_analytics/cohort_orders/',
    productAnalyticsProductInfo: '/product_analytics/product/:id',
    productAnalyticsActiveProducts: '/product_analytics/active_products',
    productAnalyticsActiveProductsManual:
        '/product_analytics/active_products/manual/',
    productAnalyticsActiveProductsXls:
        '/product_analytics/active_products/xlsx/',
    productAnalyticsProductInfoId: (id) => `/product_analytics/product/${id}`,

    profit: '/profit/',
    profitMargin: '/profit/margin_profit/',
    profitMarginProfitProducts: '/profit/margin_profit/products/',
    profitMarginProfitCompare: '/profit/margin_profit/compare/',
    profitUnit: '/profit/unit_economy/',
    profitUnitActual: '/profit/unit_economy/actual',
    profitUnitForecast: '/profit/unit_economy/forecast',
    profitUnitPromotion: '/profit/unit_economy_promotion/',
    profitCosts: '/profit/costs/',

    deliveriesRegions: '/deliveries_regions/',
    deliveriesRegionsSupplyForecast: '/deliveries_regions/supply_forecast/',
    deliveriesRegionsSupplyForecastRegions:
        '/deliveries_regions/supply_forecast_regions/',
    deliveriesRegionsSalesRegion: '/deliveries_regions/sales_region/',
    deliveriesRegionsSalesRegionByRegions:
        '/deliveries_regions/sales_region/by_regions/',
    deliveriesRegionsSalesRegionByProducts:
        '/deliveries_regions/sales_region/by_products/',
    deliveriesRegionsSalesRegionRecommendedStocks:
        '/deliveries_regions/sales_region/recommended_stocks/',
    deliveriesRegionsRegionalStocks: '/deliveries_regions/regional_stocks/',

    promotion: '/promotion/',
    notificationTelegram: '/notification_telegram/',
    helpList: '/help-list/',
    academy: '/academy/',
    oauth: '/oauth/:marketplace/',
    confirm: '/confirm/:confirm/',
    quiz: '/quiz/',
    quizSurvey: '/quiz/survey',
    quizEnd: '/quiz/end',
    adminUsers: '/admin-users/',
    adminUsersFileSharing: '/admin-users/file_sharing/',
    adminUsersFileSharingFiles: '/admin-users/file_sharing/files/',
    adminUsersFileSharingLink: '/admin-users/file_sharing/link/',
    adminUsersDataImport: '/admin-users/data_import/',
    adminPartners: '/admin-partners/',

    finance: '/finance/',
    financeStandard: '/finance/standard/',
    financeComparison: '/finance/comparison/',
    financeDynamics: '/finance/dynamics/',

    notifications: '/notify/',
    delegateUsers: '/delegate-users/',

    main: '/main/',
    dashboard: '/main/dashboard/',
    recommendations: '/main/recommendations/',
    recommendationsLogistic: '/main/recommendations/logistic/',

    supply: '/supply/',
    supplyForecast: '/supply/forecast/',
    supplyRemaining: '/supply/remaining/',
    supplyRemainingOwnWarehouse: '/supply/remaining/own_warehouse/',
    supplyRemainingRemainder: '/supply/remaining/remainder/',
    supplyBoxRatio: '/supply/box_ratio/',

    adv: '/adv/',
    advByProducts: '/adv/by_products/',
    advByCampaigns: '/adv/by_campaigns/',

    accesses: '/accesses/',
})

export const AuthUrl = Object.freeze({
    postLogin: '/api/auth/login/',
    postSignUp: '/api/auth/signup/',
    confirmSms: '/api/auth/confirm_sms/',
    confirmEmail: (token) => `/api/auth/confirm_email/${token}/`,
    confirmEmailRepeat: '/api/auth/confirm_email/',
    getProviders: '/api/auth/providers/',
    getCallbackProviders: (providerName) =>
        `/api/auth/callback/${providerName}/`,
    getLogout: '/api/auth/logout/',
    postResetPasswordByEmail: '/api/auth/reset_password_by_email/',
    postResetPasswordByPhone: '/api/auth/reset_password_by_phone/',
    resetPassword: (token) => `/api/auth/reset_password/${token}/`,
    validateToken: (userUuid) => `/api/auth/validate_token/${userUuid}/`,
    postRefresh: '/api/auth/refresh/',
})

export const MarketUrl = Object.freeze({
    loginOzon: '/pim/api/marketplace/account/login_ozon/',
    editOzonId: (id) => `/pim/api/marketplace/account/login_ozon/${id}/`,
    loginWb: '/pim/api/marketplace/account/login_wb/',
    editWbId: (id) => `/pim/api/marketplace/account/login_wb/${id}/`,
    loginYandex: '/pim/api/marketplace/account/login_yandex/',
    editYandexId: (id) => `/pim/api/marketplace/account/login_yandex/${id}/`,
    ssoYandex: '/pim/api/marketplace/account/sso_yandex/',
    checkSsoYandex: '/pim/api/marketplace/account/check_sso/',
    getCampaignsYandex: '/pim/api/marketplace/account/get_campaigns/',
    credentialList: '/pim/api/marketplace/account/v2/get_list/',
    deleteCredential: '/pim/api/marketplace/account/delete/',
    deleteUnsubscribe: (id) =>
        `/pim/api/marketplace/account/unsubscribe/${id}/`,
    deleteUnsubscribes: `/pim/api/marketplace/account/unsubscribe/`,
    credentialsStatusesOld: '/pim/mp-import/v4/status/',
    credentialsStatuses: '/pim/mp-import/v5/status/',
    ssoWb: '/pim/api/marketplace/account/sso_wb/',
    ssoWbEdit: (id) => `/pim/api/marketplace/account/sso_wb/${id}/`,
    checkSsoWb: '/pim/api/marketplace/account/check_sso_wb/',
})

export const BillingUrl = Object.freeze({
    tariffsDirectory: '/pim/api/tariff/simple-list-tariffs/',
    purchaseCallback: '/pim/api/tariff/purchase/callback/tink/',
    purchaseTink: '/pim/api/tariff/billing/purchase/tink/',
    callbackTink: '/pim/api/tariff/billing/callback/tink/',
    subscriptionTariff: '/pim/api/tariff/billing/subscription-view/',
    billingDict: '/pim/api/tariff/billing/dict/',
    billingBundles: '/pim/api/tariff/billing/bundles/',
})

export const DadataUrl = Object.freeze({
    companySuggests: '/suggest/party',
    companyAddress: '/suggest/address',
    bankSuggests: (suggestType) => `/${suggestType}/bank`,
})

export const CallTouchUrl = Object.freeze({
    register: '/register/',
})

export const ErrorUrl = Object.freeze({
    error: () => '/sendMessage',
})

export const FeedbackUrl = Object.freeze({
    message: '/pim/api/mailer/send/',
})

export const TelegramUrl = Object.freeze({
    telegramToken: '/pim/api/telegram/token/',
    telegramUrl: '/pim/api/telegram/url/',
    telegramQr: '/pim/api/telegram/qr/',
    telegramCommonNotifications: '/pim/api/telegram/common-notifications/',
    telegramNotificationTypes: '/pim/api/telegram/notification_types/',
    telegramNotificationSlug: (slug) =>
        `/pim/api/telegram/notification/${slug}/`,
    telegramAccount: '/pim/api/telegram/account/',
    telegramNotificationMpChange: '/pim/api/telegram/notification-mp-change/',
    telegramSettings: '/pim/api/telegram/telegram-settings/',
    telegramSwitchTimeSet: '/pim/api/telegram/switch-time-set/',
    telegramTimeSet: '/pim/api/telegram/time-set/',
})

export const NotificationsUrl = Object.freeze({
    notifications: '/pim/api/notifications/',
    notificationsSelf: '/pim/api/notifications/self/',
    notificationPrivate: '/pim/api/notifications/',
    notificationView: '/pim/api/notifications/view/',

    notificationsAdmin: '/pim/api/admin/notifications/',
    notifyAdminId: (id) => `/pim/api/admin/notifications/${id}`,
})

export const AnalyticsUrl = Object.freeze({
    abcRevenueData: '/pim/reports/dashboard/abc_revenue/',
    abcReport: '/reports/abc',
    abcReportByProducts: '/reports/abc_by_products',
    commodityReportXls: '/pim/commodity_report/get_xlsx',
    abcReportXls: '/pim/abc_analyze/get_xlsx',
    postCost: (cabinetId, productId) =>
        `/pim/reports/references/cost/${cabinetId}/${productId}`,
    costHistory: (cabinetId, productId) =>
        `/pim/reports/references/cost/${cabinetId}/${productId}`,
    deleteCostHistory: (cabinetId, productId, dateFrom) =>
        `/pim/reports/references/cost/${cabinetId}/${productId}/${dateFrom}`,
    taxHistory: '/pim/reports/references/tax',
    postTax: '/pim/reports/references/tax',
    deleteTaxHistory: (recordId) => `/pim/reports/references/tax/${recordId}`,
    unitProfitThr: '/reports/profit/unit/profit_thr',
    unitByProducts: '/pim/reports/unit_economy/',
    unitByProductsTotal: '/pim/reports/unit_economy_total/',
    unitCard: '/reports/profit/unit',
    costFile: `/pim/reports/references/costs/filling`,
    costCreateStatusFile: (taskUuid) =>
        taskUuid
            ? `/pim/reports/references/costs/filling/create/status/${taskUuid}`
            : `/pim/reports/references/costs/filling/create/status/`,
    costHistoryList: `/pim/reports/references/costs/filling/history`,
    costStatus: (taskUuid) =>
        taskUuid
            ? `/pim/reports/references/costs/filling/status/${taskUuid}`
            : '/pim/reports/references/costs/filling/status/',
    costDownload: (taskUuid) =>
        `/pim/reports/references/costs/filling/download/${taskUuid}`,
})

export const UnitUrl = Object.freeze({
    unitList: '/pim/reports/unit_economy/v2/unit/',
    unitListV2: '/pim/reports/unit_economy_new_v2/',
    unitPromoList: '/pim/reports/unit_economy_promo/',
    unitTotal: '/pim/reports/unit_economy_total_new/',
    unitSettingsHistory: '/pim/reports/unit_economy_new_v2/history/',
    unitInfo: '/pim/api/reports/unit_economy/v2/info/',

    unitCalcList: '/pim/api/reports/unit_economy/v2/forecast/',
    unitCalcInfo: '/pim/api/reports/unit_economy/v2/forecast/info/',
})

export const SupplyForecastsUrl = Object.freeze({
    supplyForecast: '/pim/reports/supply_forecast/',
    supplyForecastProducts: '/pim/reports/supply_forecast_products/',
    supplyForecastTotal: 'pim/reports/supply_forecast_total/',
    // supplyForecast: '/pim/reports/supply_forecast_new/',
    supplyForecastSize: '/pim/reports/supply_forecast_sizes_new/',
    // supplyForecastTotal: 'pim/reports/supply_forecast_total_new/',
    supplyForecastOptions: '/pim/reports/supply_forecast/days_options',
    supplyForecastProvideStocks: '/pim/reports/supply_forecast/provide_stocks',
    supplyForecastActualDays: '/pim/reports/supply_forecast/actual_days',
    supplyForecastXls: '/pim/reports/supply_forecast/get_xlsx',
})

export const RegionalSupplyUrl = Object.freeze({
    regionalSupplyTotal: '/pim/reports/regional_supply_total/',
    regionalSupply: '/pim/reports/regional_supply/',
    regionalSupplyProducts: '/pim/reports/regional_supply_by_cabinet/',
    regionalSupplyBySize: '/pim/reports/regional_supply_by_size/',
    regionalSupplyTotalV3: '/pim/reports/regional_supply/v3/total/',
    regionalSupplyByCabinetV3: '/pim/reports/regional_supply/v3/by_cabinet/',
    regionalSupplyBySizeV3: '/pim/reports/regional_supply/v3/by_size/',
})

export const InformerUrl = Object.freeze({
    informerData: (randomId) => `/api/data.json?${randomId}`,
})

export const UrlDisabledUrl = Object.freeze({
    urlDisabledData: (randomId) => `/api/urlDisabled.json?${randomId}`,
})

export const AdminUrl = Object.freeze({
    users: (page) => `/api/users/${page}/`,
    usersLogin: (page) => `/api/users/login/${page}/`,
    usersLogout: '/api/users/logout/',
    setTariff: '/pim/api/tariff/subscription/set-tariff/',
    usersCreate: '/api/users/user/',
    mpAccount: '/api/users/mp_accounts/',
    tariffs: '/api/users/tariffs/',
    subscribeToDemo: '/pim/api/marketplace/account/subscribe_to_demo/',
    emailConfirm: '/api/users/set_email_confirmed/',
    phoneConfirm: '/api/users/set_phone_confirmed/',
    deleteCostsConfirm: (uuid) => `/pim/reports/costs/${uuid}`,
    managers: '/api/users/managers/',
    userEdit: (uuid) => `/api/users/user/${uuid}/`,
})

export const DelegateUrl = Object.freeze({
    delegates: `/api/users/delegate/`,
})

export const ProfileUrl = Object.freeze({
    profile: '/api/users/profile/',
    profileAvatar: '/api/users/profile/avatar/',
    profileConfirmChangePhone: '/api/users/confirm_change_phone/',
    profileConfirmUser: (userUuid) => `/api/users/confirm_user/${userUuid}/`,
    profileConfirmChangeEmail: (token) =>
        `/api/users/confirm_change_email/${token}/`,
})

export const WidgetUrl = Object.freeze({
    marginProfitExpenses: '/pim/reports/dashboard/v2/margin_profit_expenses/',
})

export const ReportsStatusUrl = Object.freeze({
    fileInfo: '/file/info/',
    fileInfoUpload: '/file/info_upload/',
    deletePromoItem: (uuid) => `/pim/reports/promo/${uuid}/`,
    fileList: '/file/list/',
    fileLast: '/file/last/',
    getFile: '/file/get_xlsx/',

    marginXlsx: '/pim/reports/margin/v2/xlsx/margin/',
    standardXlsx: '/pim/reports/financial_xlsx/',
    standardExpertXlsx: 'pim/reports/financial/v2/xlsx/expert/',
    abcXlsx: '/pim/reports/abc_analyze_new_xlsx/',
    commodityXlsx: '/pim/reports/commodity/v2/xlsx/commodity/',
    unitXlsx: '/pim/reports/unit_economy/v2/xlsx/unit/',
    calcXlsx: '/pim/api/reports/unit_economy/v2/xlsx/forecast/ ',
    forecastXlsx: '/pim/api/reports/supply_forecast/v2/xlsx/advanced/ ', //Supply
    advXlsx: '/pim/reports/advert/v2/xlsx/',
    advByCompaniesXlsx: '/pim/reports/advert/v2/xlsx/campaigns/list/',
    unitRegionalStocksXlsx: '/pim/reports/regional_stocks_xlsx/',
    unitPromoXlsx: '/pim/reports/unit_economy_promo_xlsx/',
    supplyForecastXlsx: '/pim/reports/supply_forecast_new_xlsx/',
    byProductsXlsx: '/pim/reports/regional_sales_products/v2/xlsx/',
    supplyForecastRegionalXlsx: '/pim/reports/regional_supply_xlsx/',
    supplyForecastRegionalV3Xlsx: '/pim/reports/regional_supply/v3/xlsx/',
    costOldXlsx: '/pim/reports/costs/cost_xlsx/',
    costXlsx: '/pim/reports/costs/v2/xlsx/products/',
    activeXlsx: '/pim/reports/active/active_xlsx/',
    promoXlsx: '/pim/reports/promo/promo_xlsx/',
    marginCompareXlsx: '/pim/api/reports/margin/v2/xlsx/margin_comparison/',
    cohortXlsx: '/pim/api/reports/cohort/xlsx/list/',
    abcCompareXlsx: '/pim/api/reports/abc_analyze/v2/xlsx/compare/',
    recommendationsLogisticXlsx:
        '/pim/api/reports/recommendations/v2/xlsx/logistic/',
    compareXlsx: '/pim/reports/financial/v2/xlsx/compare/',
    dynamicXlsx: '/pim/reports/financial/v2/xlsx/dynamics/',
    ownWarehousesXlsx: '/pim/reports/warehouse_stock/warehouse_stock_xlsx/',
    boxRatioXlsx: '/pim/reports/box_units/box_units_xlsx/',
    groupXlsx: '/pim/reports/products_for_group/list_xlsx/',
    remainderXlsx: '/pim/reports/supply_forecast/v2/supply/upload_xlsx/',
})
export const ProductsSettingsUrl = Object.freeze({
    productsList: 'pim/reports/product_info/v2/list-products/',
    setActivityProduct: 'pim/reports/product_info/set-activity/',
})
export const ProductInfoUrl = Object.freeze({
    productInfoCard: '/pim/reports/product_info/card_new/',
    productInfoAbc: '/pim/reports/product_info/abc_profit_new/',
    productInfoWidgets: '/pim/reports/product_info/widgets_new/',
    productInfoStockSalesBalance:
        '/pim/reports/product_info/stock_sales_balance_new/',
    productInfoUnitEconomy: '/pim/reports/product_info/unit_economy_new/',
    productInfoMarginExpenses: '/pim/reports/product_info/margin_expenses_new/',
    productInfoMarginGraph: '/pim/reports/product_info/margin_graph_new/',
    productInfoTransactions: '/pim/reports/product_info/transactions_new/',
})

export const RegionalStocksUrl = Object.freeze({
    regionalStocks: '/pim/reports/regional_stocks/',
    regionalStocksTotal: '/pim/reports/regional_stocks_total/',
    regionalStocksForSize: '/pim/reports/regional_stocks_for_size/',
})

export const RepostSettingsUrl = Object.freeze({
    settingsTypeReports: '/pim/reports/settings/type_reports/',
    settingsProfitability: '/pim/reports/settings/profitability/',
    settingsTax: '/pim/reports/settings/tax/',
    settingsActiveProduct: '/pim/reports/settings/active_product/',
})

export const OnboardingUrl = Object.freeze({
    needAudit: '/api/users/need_audit/',
    onboardCurrentStep: '/api/onboard/current/',
    onboardSetStep: '/api/onboard/set/',
})

export const SalesRegionByProductUrl = Object.freeze({
    salesByProducts: '/pim/reports/regional_sales_products/v3/agg/',
    salesProductTotalsByRegion: '/pim/reports/totals_regional_sales_by_region/',
    salesProductTotalsByGroup: '/pim/reports/totals_regional_sales_by_group/',
    macroRegion: '/pim/reports/get_macro_regions/',
})

export const AbcUrl = Object.freeze({
    abcAnalyze: '/pim/reports/abc_analyze_new/',
    abcAnalyzeTotal: '/pim/reports/abc_analyze_total_new/',
    brandList: '/pim/reports/brand_list/',
    categoryList: '/pim/reports/category_list/',
    compareFilters: '/pim/api/reports/abc_analyze/v2/filters/',
    compareList: '/pim/api/reports/abc_analyze/v2/compare/',
})

export const FileSharingUrl = Object.freeze({
    downloadFile: '/file_sharing/get_file/',
    fileListUser: '/file_sharing/get_files_list_user/',
    fileListAdmin: '/file_sharing/get_files_list_admin/',
    uploadFileS3: '/file_sharing/upload_files_to_s3/',
    addFiles: '/file_sharing/add_files/',
    editFile: '/file_sharing/edit_file/',
    deleteFile: '/file_sharing/delete_file/',
})

export const SalesRegionRecommendedStocksUrl = Object.freeze({
    whGroupByRecGroup: '/pim/reports/get_sales_region_wh_group_by_rec_group/',
    recWhGroups: '/pim/reports/get_rec_wh_groups/',
})

export const SalesRegionByRegionUrl = Object.freeze({
    totalsRegionalSales: '/pim/reports/totals_regional_sales_new/',
    salesRegionalTotalsByRegion:
        '/pim/api/reports/regional/v2/by_region/totals/',
    salesRegionalTotalsByGroup: '/pim/api/reports/regional/v2/by_group/totals/',
    salesAllRegions: '/pim/reports/get_sales_for_all_regions_new/',
    salesRegionWhGroup: '/pim/reports/get_sales_region_wh_group/',
    salesByCabinet: '/pim/reports/get_sales_by_cabinet_new/',
    salesByRegion: '/pim/api/reports/regional/v2/by_region/',
    salesByRegion2: '/pim/reports/regional/v3/by_region/',
    salesByGroup: '/pim/api/reports/regional/v2/by_group/',
})

export const AdminDataImportUrl = Object.freeze({
    listImportsByUser: '/pim/api/mp_import_v2/list_imports_by_user/',
    listDetailedImports: '/pim/api/mp_import_v2/list_detailed_imports_by_job/',
    createImportJob: '/pim/api/mp_import_v2/create_import_job/',
})

export const DashboardUrl = Object.freeze({
    resupplyData: '/pim/reports/dashboard/resupply/',
    chartData: '/pim/reports/dashboard/db_orders/',
    cardsData: '/pim/reports/dashboard/db_stats/',
    profitabilityData: '/pim/reports/dashboard/profitability/',
    stockTurnoverData: '/pim/reports/dashboard/stock_turnover/',
    abcRevenueData: '/pim/reports/dashboard/abc_revenue/',
    stockBalanceData: '/pim/reports/dashboard/stock_balance/',
    productsNoCostAndTgData: '/pim/reports/dashboard/no_cost_and_tg/',
})

export const CommodityUrl = Object.freeze({
    commodityReport: '/pim/reports/commodity/v2/commodity/',
    commodityTotal: '/pim/reports/commodity/v2/total/',
})

export const MarginProfitUrl = Object.freeze({
    marginProfit: '/pim/reports/margin/v2/margin/',
    marginProfitTotal: '/pim/reports/margin/v2/total/',
    marginProfitTransactions: `/pim/reports/margin_transactions/`,
    marginProfitCompare: `pim/api/reports/margin/v2/margin_comparison/`,
    marginGpt: '/pim/api/reports/margin/gpt/',
})

export const AdvUrl = Object.freeze({
    advStatistics: '/pim/reports/advert/v2/statistics/',
    advTotals: '/pim/reports/advert/v2/total/',
    advCampaignsList: '/pim/reports/advert/v2/campaigns/list/',
    advCampaignsProducts: '/pim/reports/advert/v2/campaigns/products/',
    advCampaignsTotals: '/pim/reports/advert/v2/campaigns/totals/',
})

export const FinanceUrl = Object.freeze({
    standard: '/pim/reports/financial/',
    standardTotal: '/pim/reports/financial_total/',
    standardExpert: '/pim/reports/financial/v2/expert/',
    standardTotalExpert: '/pim/reports/financial/v2/total_expert/',
    comparison: '/pim/reports/financial/v2/compare/',
    dynamics: '/pim/reports/financial/v2/dynamics/ ',
})

export const TaxUrl = Object.freeze({
    taxDict: '/pim/api/reports/tax/dict/',
    tax: (id) => `/pim/api/reports/tax/${id}/`,
})

export const ExpenseSellerUrl = Object.freeze({
    expenseDict: '/pim/api/reports/expense/dict/',
    expense: (id) => `/pim/api/reports/expense/${id}/`,
})

export const ImportWbUrl = Object.freeze({
    wbDetailed: '/pim/api/reports/wb_detailed/',
    wbDetailedSource: '/pim/api/reports/wb_detailed/source/',
    wbDetailedRecalc: '/pim/api/reports/wb_detailed/recalc/',
    wbDetailedList: '/pim/api/reports/wb_detailed/list/',
})

export const CohortUrl = Object.freeze({
    cohortList: '/pim/api/reports/cohort/list/',
    cohortTotal: '/pim/api/reports/cohort/total/',
})

export const GroupsUrl = Object.freeze({
    products: '/pim/reports/product_info/for_group/',
    productsInfo: '/pim/reports/product_info/for_group/info/',
    groups: '/pim/api/groups/',
    groupsId: (id) => `/pim/api/groups/${id}/`,
})

export const RecommendationUrl = Object.freeze({
    logistics: '/pim/api/reports/recommendations/v2/logistic/',
    dict: '/pim/api/reports/dict/recommendations/',
})

export const SupplyUrl = Object.freeze({
    forecast: '/pim/reports/supply_forecast/v2/advanced/',
    supply: '/pim/reports/supply_forecast/v2/supply/',
    dictWhGroup: '/pim/api/reports/dict/wh_group/',
    supplyItem: (id) => `/pim/reports/supply_forecast/v2/supply/${id}/`,
})

export const ContractsUrl = Object.freeze({
    contracts: '/pim/api/contracts/',
    employeeContracts: (headId, employeeId) =>
        `/pim/api/contracts/${headId}/${employeeId}/`,
})
