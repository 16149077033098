import {ImagesPreview} from '@components/elements'
import Screen1 from '../../images/screen-1.png'
import Screen2 from '../../images/screen-2.png'

export const WildberriesHelp = () => {
    return (
        <ImagesPreview>
            <div className={'w-full'}>
                <p className={'mb-4 dark:text-gray-200'}>
                    <strong>Внимание:</strong> Если у вас подключено несколько
                    кабинетов Wildberries, привязанных к одному номеру телефона,
                    выберите нужный кабинет в выпадающем списке справа.
                </p>
                <p className={'mb-4 dark:text-gray-200'}>
                    <strong>Важно!</strong> API-ключ возможно сформировать
                    только в роли “Владелец” в аккаунте кабинета Wildberries.
                </p>
                <p className={'mb-4 dark:text-gray-200'}>
                    Чтобы найти API-ключ, перейдите по этой ссылке:{' '}
                    <a
                        className={'text-blue-500 hover:text-blue-400'}
                        href={
                            'https://seller.wildberries.ru/supplier-settings/access-to-api'
                        }
                        target={'_blank'}
                    >
                        https://seller.wildberries.ru/supplier-settings/access-to-api
                    </a>
                </p>
                <p className={'mb-4 dark:text-gray-200'}>
                    Если ссылка не открывается, найдите нужный раздел вручную. В
                    правом верхнем углу наведите курсор мышки на название
                    компании ⟶ в открывшемся окне профиля нажмите на «Настройки»
                    ⟶ перейдите во вкладку «Доступ к API».
                </p>
                <p className={'mb-4 dark:text-gray-200'}>
                    Введите название ключа (оно может быть любым). Выберите все
                    доступные методы API, установите галочку “Только на чтение”
                    и нажмите на кнопку «Создать токен».
                </p>
                <img src={Screen1} className={'mb-6'} alt={'Screen1'} />
                <img src={Screen2} className={'mb-6'} alt={'Screen2'} />
                <p className={'mb-4 dark:text-gray-200'}>
                    Вставьте скопированный текст токена в поле “API-ключ” на
                    платформе Seller24.
                </p>
                <p className={'mb-4 dark:text-gray-200'}>
                    <strong>Важно!</strong> API-ключ отображается один раз.
                    После перезагрузки страницы его нельзя посмотреть, можно
                    только удалить. Поэтому рекомендуем сразу скопировать и
                    сохранить созданный API-ключ.
                </p>
            </div>
        </ImagesPreview>
    )
}
